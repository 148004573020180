import React from 'react'

const Footer = () => {
  return (
    <div className='max-w-[1200px] sm:h-[150px] p-12 flex justify-between mx-auto'>
        <span className='primary-color'>K. KANGUR</span>
        <div className='text-gray-700 flex flex-col'>
          <p>kevinkangur13@gmail.com</p>
          <a href="https://twitter.com/kangur_kevin" target='_blank'>Twitter</a>
          <a href="https://www.facebook.com/kevin.kangur.752" target='_blank'>FaceBook</a>
        </div>
    </div>
  )
}

export default Footer
