import React from 'react'
import food from '../assets/food-proj.png'
import aistartup from '../assets/ai-startup-proj.png'
import apple from '../assets/apple-store-proj.png'
import folio from '../assets/portfolio-web-proj.png'
import snake from '../assets/snake-proj.png'
import tetris from '../assets/tetris-proj.png'

const Work = () => {
  return (
    <div className='max-w-[1200px] mx-auto p-5' id='work'>
      <div className='pb-8'>
        <p className='text-4xl mb-3 font-bold primary-color'>Work</p>
        <p className='text-gray-400'>Check out some of my work</p>
      </div>

      <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>

        <div className='transform transition-transform duration-300 hover:scale-105 overflow-hidden shadow-lg shadow-[#040c16] 
          group container rounded-md flex justify-center items-center h-[200px] bg-cover relative'>
          <img src={food} alt="" />
          <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 
            flex flex-col justify-center items-center'>
            <span className='text-2xl font-bold text-white tracking-wider'>Design from Figma</span>
            <div className='pt-8 text-center'>
              <a href="https://github.com/KevinKangur/food-app" target='_blank'>
                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                  GitHub
                </button>
              </a>
            </div>
          </div>
        </div>
        
        <div className='transform transition-transform duration-300 hover:scale-105 overflow-hidden shadow-lg shadow-[#040c16] 
          group container rounded-md flex justify-center items-center h-[200px] bg-cover relative'>
          <img src={aistartup} alt="" />
          <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 
            flex flex-col justify-center items-center'>
            <span className='text-2xl font-bold text-white tracking-wider'>Design from Figma</span>
            <div className='pt-8 text-center'>
              <a href="https://github.com/KevinKangur/AI-startup-tool" target='_blank'>
                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                  GitHub
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className='transform transition-transform duration-300 hover:scale-105 overflow-hidden shadow-lg shadow-[#040c16] 
          group container rounded-md flex justify-center items-center h-[200px] bg-cover relative'>
          <img src={apple} alt="" />
          <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 
            flex flex-col justify-center items-center'>
            <span className='text-2xl font-bold text-white tracking-wider'>Recreated design</span>
            <div className='pt-8 text-center'>
              <a href="https://github.com/KevinKangur/apple-store-with-tailwind" target='_blank'>
                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                  GitHub
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className='transform transition-transform duration-300 hover:scale-105 overflow-hidden shadow-lg shadow-[#040c16] 
          group container rounded-md flex justify-center items-center h-[200px] bg-cover relative'>
          <img src={folio} alt="" />
          <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 
            flex flex-col justify-center items-center'>
            <span className='text-2xl font-bold text-white tracking-wider'>Design from Figma</span>
            <div className='pt-8 text-center'>
              <a href="https://github.com/KevinKangur/portfolio_website" target='_blank'>
                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                  GitHub
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className='transform transition-transform duration-300 hover:scale-105 overflow-hidden shadow-lg shadow-[#040c16] 
          group container rounded-md flex justify-center items-center h-[200px] bg-cover relative'>
          <img src={snake} alt="" />
          <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 
            flex flex-col justify-center items-center'>
            <span className='text-2xl font-bold text-white tracking-wider'>Unfinished</span>
            <div className='pt-8 text-center'>
              <a href="https://github.com/KevinKangur/snake-games" target='_blank'>
                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                  GitHub
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className='transform transition-transform duration-300 hover:scale-105 overflow-hidden shadow-lg shadow-[#040c16] 
          group container rounded-md flex justify-center items-center h-[200px] bg-cover relative'>
          <img src={tetris} alt="" />
          <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 
            flex flex-col justify-center items-center'>
            <span className='text-2xl font-bold text-white tracking-wider'>Unfinished</span>
            <div className='pt-8 text-center'>
              <a href="https://github.com/KevinKangur/making-tetris" target='_blank'>
                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                  GitHub
                </button>
              </a>
            </div>
          </div>
        </div>

      </div>
      
    </div>
  )
}

export default Work
