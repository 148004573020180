import React from 'react'
import dog from '../assets/dog.png'

const About = () => {
  return (
    <div className='text-white max-w-[1200px] mx-auto my-12' id='about'>

        <div className='md:grid md:grid-cols-2 sm:py-16'>

            <div className='mt-4 md:mt-0 text-left flex'>
                <div className='my-auto mx-6'>
                    <h2 className='text-4xl font-bold mb-4 primary-color'>About Me</h2>
                    <p className='text-base lg:text-lg'>
                        My name is Kevin Kangur. 
                        I'm a 19 year old IT student at Kuressaare Ametikool (Kuressaare Regional Training Centre). 
                        I am currently in my third and final year at the school. <br/>
                        Born, raised and still living in Estonia, Saaremaa. 
                        I can read, write and speak both Estonian and English. <br/>
                        I'm always ready to learn new skills and sharpen my already existing ones.
                        I hope to one day be able to use my skills to help build and develop something that I could be proud of.
                    </p>
                </div>
            </div>

            <img src={dog} alt='my-dog' className='mx-auto rounded-3xl py-8 md:py-0' width={300} height={300}/>

        </div>
    </div>
  )
}

export default About
