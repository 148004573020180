import React from 'react'
import heroimage from '../assets/profile.png'
import cvfile from '../assets/CV - Kevin Kangur.pdf'
import { TypeAnimation } from 'react-type-animation'

const Hero = () => {
  return (
    <div className='grid gird-cols-1 sm:grid-cols-3 gap-8 max-w-[1200px] md:h-[70vh] mx-auto py-8 bg-black'>

        <div className='col-span-1 my-auto mx-auto w-[300px] h-auto lg:w-[400px]'>
            <img src={heroimage} alt="hero image" className='rounded-full'/>
        </div>

        <div className='col-span-2 px-5 my-auto'>

            <h1 className='text-white text-4xl sm:text-5xl lg:text-[90px] font-extrabold'>
                <span className='primary-color'>
                    I'm a
                </span> <br/>
                <TypeAnimation
                    sequence={[
                        'Web Developer',
                        1000,
                        'Student',
                        1000
                    ]}
                    wrapper='span'
                    speed={10}
                    repeat={Infinity}
                />
            </h1>

            <p className='text-white sm:text-lg my-6 lg:text-xl'>
                My name is Kevin Kangur and I'm a third year IT student at Kuressaare Ametikool.
            </p>

            <div className='my-8'>
                <a href={cvfile} download className='px-6 py-3 w-full rounded-xl mr-4 
                bg-gradient-to-br from-[#fc00ff] to-[#00dbde] text-white'>
                    Download CV
                </a>
            </div>
        </div>
      
    </div>
  )
}

export default Hero
